import axios from 'axios';
import authHeader from './auth-header';

//const API_URL = 'http://localhost:3000/api/business/';
//const API_URL = 'http://localhost:3003/api/business/';
//const API_URL = 'http://homolog.ganbei.io/api/business/';
const API_URL = `${process.env.VUE_APP_API_URL}business/`;


class CompanyService {
  getCompanies() {
    return axios.get(API_URL);
  }

  get(id) {
    return axios.get(API_URL + 'findOne/' + `${id}`);
  }

  update(id) {
    return axios.put(API_URL + 'update/' + `${id}`, {
        name: user,
        doc: user,
        phone: user,
        address: user,
        city: user,
        state: user,
        zipcode: user
      });
  }

  register(user) {
    return axios.post(API_URL + 'create', {
      username: user.username,
      email: user.sellerEmail,
      businessName: user.businessName,
      businessType: "PF",
      businessDoc: user.businessDoc,
      businessPhone: user.sellerPhone,
      businessEmail: user.sellerEmail,
      sellerPhone: user.sellerPhone,
      sellerEmail: user.sellerEmail,
      sellerDoc: user.sellerDoc,
    });
  }
}

export default new CompanyService();
