import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Notifications from '@kyvg/vue3-notification';
import VueGoodTablePlugin from 'vue-good-table-next';
import { createPinia } from 'pinia';
import VuePlyr from 'vue-plyr';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue-good-table-next/dist/vue-good-table-next.css';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/css/app.css";
import 'vue-plyr/dist/vue-plyr.css';
import 'vue3-easy-data-table/dist/style.css';

createApp(App)
  .use(Notifications)
  .use(router)
  .use(store)
  .use(createPinia())
  .use(VueGoodTablePlugin)
  .use(VuePlyr, {"volume": "1"})
  .component('EasyDataTable', Vue3EasyDataTable)
  //.component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");