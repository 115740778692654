import { createStore } from "vuex";
import { auth } from "./auth.module";
import { lead } from "./lead.module";
import { seller } from "./seller.module";
import { company } from "./company.module";
import { userAdmin } from "./user.module";

const store = createStore({
  modules: {
    auth,
    lead,
    seller,
    company,
    userAdmin
  },
});

export default store;
