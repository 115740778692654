import axios from 'axios';
import authHeader from './auth-header';

//const API_URL = 'http://localhost:3000/api/seller/';
//const API_URL = 'http://localhost:3003/api/seller/';
//const API_URL = 'http://homolog.ganbei.io/api/seller/';
const API_URL = `${process.env.VUE_APP_API_URL}seller/`;

class SellerService {
  
    all() {
        return axios.get(API_URL);
    }
  
    // getSellers(companyid) {
    //     return axios.get(API_URL + `allcompanysellers/${companyid}`);
    // }

    getSellers (bid) {
      return axios.get(API_URL + 'business/' + bid)
      .then(response => {
        //console.log("vai vai", response.data)
        return response.data;
      });
    }

    register(user) {
        console.log(user);
        return axios.post(API_URL + 'create', {
            businessId: user.companyid,
            username: user.username,
            email: user.sellerEmail,
            phone: user.sellerPhone,
            doc: user.sellerDoc,
            birthday: user.birthday,
            goal: user.sellerGoal,
            role: user.role
        })
        .then(response => {
          console.log("sucesso", response.data);
          return response.data;
        });
      }

  get(id) {
    return axios.get(API_URL + `${id}`);
  }
}

export default new SellerService();
