import axios from 'axios';
import authHeader from './auth-header';

//const API_URL = 'http://localhost:3000/api/test/';
//const API_URL = 'http://localhost:8080/api/test/';
//const API_URL = 'http://homolog.ganbei.io/api/test/';
const API_URL = `${process.env.VUE_APP_API_URL}test/`;
const API_URL_USER = `${process.env.VUE_APP_API_URL}users/`;

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  get(id) {
    return axios.get(API_URL_USER + 'find-one/' + `${id}`);
  }

  getAdmins() {
    return axios.get(API_URL_USER + 'all-admins')
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log("auth service log", error.response.data.message);
    });
  }
}

export default new UserService();
