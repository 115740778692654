import UserService from '../services/user.service';

const user = JSON.parse(localStorage.getItem('user'));
// const initialState = user
//   ? { status: { loggedIn: true }, user }
//   : { status: { loggedIn: false }, user: null };

export const userAdmin = {
  namespaced: true,
//   state: initialState,
  state: {
    userId: '',
  },
  actions: {
    register({ commit }, user) {
      return UserService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    setMessage(state, userId) {
      state.userId = userId;
    },
  }
};
